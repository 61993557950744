@import "variables";
@import "../_variables";
@import "../_typography";

.content-table {
    .content-table-wrapper {
        overflow-x: auto;
    }

    table {
        width: 100%;
    }

    thead {
        tr {
            border-bottom: 0.125em solid var(--tableBorderColor);
        }
    }

    th {
        min-width: 11ch;
        padding: 1.125em 0;
        padding-right: 1.375rem;

        &:last-child {
            padding-right: 0;
        }
    }

    tbody {
        tr {
            border-bottom: 0.0625em solid var(--tableRowBorderColor);
            cursor: pointer;
        }

        td {
            padding: 1.125em 0;
            padding-right: 1.375rem;

            &:last-child {
                padding-right: 0;
            }

            svg {
                width: 20px;
            }

            img {
                width: 100%;
            }
        }

        .status-column {
            border: 1px solid white;
            border-radius: 5px;
            padding: 10px 20px;
            display: inline-block;

            &.published {
                border-color: #00D098;
                color: #00D098;
            }

            &.scheduled {
                border-color: #FF8900;
                color: #FF8900;
            }

            &.expired {
                border-color: #BF0000;
                color: #BF0000;
            }

            &.archived {
                border-color: burlywood;
                color: burlywood;
            }
        }

        .status-scheduled {
            color: #FF8900;
            font-size: 15px;
        }

        .visibility-column {
            text-align: center;
        }

        .actions-column {
            text-align: right;

            div {
                display: inline-block;
                margin-left: 20px;
            }
        }
    }
}

.filter-list {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
        @extend .font-regular;

        margin: 0;
        padding: 0;
        display: inline-block;
        margin-right: 30px;
        padding-right: 30px;
        border-right: 1px solid var(--tableBorderColor);
        cursor: pointer;

        &:last-of-type {
            border: 0;
        }

        &.active {
            font-weight: bold;
        }
    }
}