@import "variables";
.repeater-list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        padding: 0;
        margin: 0;

        .field {
            padding: 0;
        }
    }
}

.hide-labels {
    li {
        legend {
            display: none;
        }
    }

    li:first-child {
        legend {
            display: block;
        }
    }
}

.repeater {
    .repeater-item {
        border-right: 1px solid #D5D5D5;

        .add-button {
            border: 2px solid var(--svgBaseColor);
            border-radius: 50%;
            display: block;
            width: 1em;
            max-width: 1em;
            height: 1em;
            font-size: 100px;
            text-align: center;
            line-height: 70px;
            margin: 0 auto;
            margin-bottom: 10px;
        }

        .add-text {
            text-transform: uppercase;
            line-height: 50px;
            font-size: 40px;
            height: 1em;
            font-weight: bold;
            text-align: center;
        }

        &.add-new-item {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            cursor: pointer;

            .col-12 {
                align-self: center;
            }

            &:hover {
                background: var(--workspaceBg);
            }
        }

        &.summary-repeater-header-item, &.summary-repeater-item {
            border-right: 0;
        }
    }
}

.summary-repeater-header-item {
    padding-bottom: 1px;
    border-bottom: 1px solid #DBDBDB;
    margin-bottom: 10px;

    h2 {
        text-align: center;
        cursor: pointer;
    }

    &.active {
        h2 {
            padding-bottom: 8px;
            border-bottom: 4px solid var(--svgBaseColor);
        }
    }
}

.summary-repeater-item {
    display: none;
    margin-bottom: 10px;

    &.active {
        display: block;
    }
}

.summary-repeater-list {
    .field-wrapper {

        p {
            margin-top: .5em;
            padding: 0 10px;
            margin-bottom: .5em;
        }

        border-bottom: 1px solid #DBDBDB !important;
        min-height: 0;
    }
}