@import "variables";
@import "../_variables.scss";

.nav-link {
    align-items: center;
    color: var(--textColorContrast);
    display: flex;
    min-height: 5em;
    padding: 0;
    position: relative;
    transition: background-color 0.5s ease-in-out;
    width: 100%;

    &.active {
        background: var(--activeMenuBgColor);
    }

    &:hover {
        color: var(--textColorContrast);
    }

    &:before, &:after {
        content: '';
        background-size: cover;
        height: 1.75em;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        min-width: 3em;
    }

    &:before {
        z-index: 3;
    }

    &:after {
        width: 3rem;
        z-index: 2;
    }

    span {
        display: none;
        text-transform: uppercase;
        padding-left: 4rem;
        width: 100%;
        white-space: nowrap;
    }

    .dashboard-links & {
        width: auto;
        background: var(--solidButtonColor);
        margin: 0 1.5em;
        align-items: flex-end;
        min-height: 6em;
        padding-bottom: 0.9em;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &:before {
            top: 2em;
        }

        &:after {
            content: none;
        }

        & span {
            display: inline-block;
            padding-left: 0;
            text-align: center;
        }
    }
}

.open .nav-link span {
    display: inline;
}

.articles-nav-link:before {
    background: url(/dist/images/icon-article.svg) center center no-repeat;
    width: 100%;
    margin-left: 0;
}

.videos-nav-link:before {
    background: url(/dist/images/icon-play.svg) center center no-repeat;
    width: 100%;
    margin-left: 0;
}

.assets-nav-link:before {
    background: url(/dist/images/icon-upload.svg) center center no-repeat;
    width: 100%;
    margin-left: 0;
}

.training-nav-link:before {
    background: url(/dist/images/icon-training.svg) center center no-repeat;
    width: 100%;
    margin-left: 0;
}

.open {
    .articles-nav-link:before {
        width: auto;
        margin-left: 0.5rem;
    }

    .videos-nav-link:before {
        width: auto;
        margin-left: 0.5rem;
    }

    .assets-nav-link:before {
        width: auto;
        margin-left: 0.5rem;
    }

    .training-nav-link:before {
        width: auto;
        margin-left: 0.5rem;
    }
}

@media screen and (max-width: $breakpoint-large) {
    .nav-link {
        span {
            display: none;
        }

        &:before, &:after {
            height: 1.25em;
        }
    }

    .open .nav-link span {
        display: none;
    }

    .articles-nav-link:before {
        width: 100%;
        margin-left: 0;
    }

    .videos-nav-link:before {
        width: 100%;
        margin-left: 0;
    }

    .assets-nav-link:before {
        width: 100%;
        margin-left: 0;
    }

    .training-nav-link:before {
        width: 100%;
        margin-left: 0;
    }

    .open {
        .articles-nav-link:before {
            width: 100%;
            margin-left: 0;
        }

        .videos-nav-link:before {
            width: 100%;
            margin-left: 0;
        }

        .assets-nav-link:before {
            width: 100%;
            margin-left: 0;
        }

        .training-nav-link:before {
            width: 100%;
            margin-left: 0;
        }
    }
}

@media screen and (max-width: $breakpoint-medium) {
    .nav-link {
        &:before, &:after {
            height: 1.25em;
        }
    }
}

@media screen and (max-width: $breakpoint-small) {
}
