@import "variables";
@import "../variables";


.sidebar {
    position: relative;
    height: 100%;
    color: var(--textColorContrast);
    transition: width 0.75s ease-in-out;
    box-shadow: 0em 0em 0.75em 0.1em #a0a0a0;

    &.slide {
        transition-property: transform;
        transform: translateX(100%);

        &.sidebar-visible {
            transform: translateX(calc(100% - 3em));

            &.open {
                transform: translateX(0);
            }
        }
    }

    .sidebar-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 3em;
        overflow-y: auto;
        transition: opacity 0.5s;

        .sidebar-body {
            flex-grow: 1;
        }
    }

    &-header {
        width: 100%;
        padding: 15% 10%;
        margin-bottom: 25px;
    }

    &-body {
        width: 100%;
    }

    &-footer {
        width: 100%;
        text-align: center;
    }
}

.sidebar-button {
    position: absolute;
    right: 0;
    top: 0;
    width: 3em;
    height: 3em;
    cursor: pointer;
    transition: transform #{$sidebar-animation-time} ease-in-out;
    transform: rotateY(180deg);
    transform-origin: center center;
    position: relative;
    z-index: 100;


    .sidebar ~ .sidebar & {
        right: auto;
        left: 0;
    }

    .button-arrow {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .svg-wrapper {
        position: absolute;
        top: 25%;
        left: 25%;
        width: 50%;
        height: 50%;

        svg {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .open & {
        transform: rotateY(0deg);
    }
}

.sidebar-footer {
    a {
        display: flex;
        min-height: 5em;
        justify-content: center;
        align-items: center;
        color: var(--textColorContrast);
    }
}